import { getStatusCode } from 'http-status-codes'
import { convertObjectToQueryString } from '../../common'
import { getShellLogger } from '../../common/logger'
import { getJiveApiBaseUrl } from '../../core/environment'
import { environment } from '../../environments'
import { authenticatedFetch } from '../auth'
import { deduplicateContacts } from './deduplicator'
import { getContactsApi, isInvictus, useContactsApi } from './helpers'
import type { AddressBook, AddressBooks, Contact, Contacts } from '@goto/contacts-definitions'
import type { ContactsQuery, MeetingProfile } from './models'
import { ProviderName } from './models'
import type { ContactAPI } from './public-api'
import { getShellAnalytics } from '../../common/shell-api-helpers'

const isFilterByOrganisation = () => false

const isUsingBff = () => false
const isPersonal = (addressBook: AddressBook) => addressBook.providerName === ProviderName.PERSONAL
const isO365Enterprise = (addressBook: AddressBook) => addressBook.providerName === ProviderName.OFFICE365_ENTERPRISE
const isO365Personal = (addressBook: AddressBook) => addressBook.providerName === ProviderName.OFFICE365
const isEnterprise = (addressBook: AddressBook) => addressBook.providerName === ProviderName.ENTERPRISE

const isNotOrganization = (addressBook: AddressBook, organizationId: string) =>
  !(addressBook.providerName === 'Organization' && addressBook.id !== organizationId)

const isSelectedAddressBook = (addressBook: AddressBook, selectedAddressBookName: string) =>
  // This matches the selected organisation address books name as it is the only common denominator between them.
  // TO DO: Have back-end add a organisation id key on address books
  addressBook.name === selectedAddressBookName ||
  isPersonal(addressBook) ||
  isO365Enterprise(addressBook) ||
  isO365Personal(addressBook) ||
  isEnterprise(addressBook)

// Support for legacy shared address book
const isLegacySharedAddressBook = (addressBook: AddressBook, organizationId: string) =>
  !(isFilterByOrganisation() && addressBook.providerName === 'Shared' && !addressBook.id?.match(organizationId))

const logNotUsingContactsTeamApi = (functionName: string) => {
  getShellAnalytics().track('GoTo > Contacts services', { functionName })
}

export class ContactService implements ContactAPI {
  private baseUrl = () =>
    isInvictus() && isUsingBff() ? `${getJiveApiBaseUrl()}/contacts-bff/v1` : `${getJiveApiBaseUrl()}/contacts/v4`
  private meetingBaseUrl = () => `${environment().apiGlobal}/rest/api`

  /**
   * The if is to catch an empty response
   * if the request is empty,the returned rest api is not standard and a change should be made
   * @returns undefined or response.json
   *
   **/
  private handleErrorAndParseJson = async (response: Response) => {
    if (!response.ok) {
      getShellLogger().error(response.statusText)
    }
    if (response.status === getStatusCode('No Content')) {
      return undefined
    }

    return await response.json()
  }
  /**
   * Returns an AddressBooks whose array of AddressBook is related to the given organizationId
   * @param addressBooks AddressBooks
   * @param organizationId string
   * @returns AddressBooks
   */
  private getSelectedOrganizationAddressBooks = (addressBooks: AddressBooks, organizationId: string) => {
    const selectedAddressBookName =
      addressBooks.items?.find(addressBook => addressBook.id === organizationId)?.name ?? ''

    return {
      ...addressBooks,
      items: (addressBooks.items ?? [])
        .filter(addressBook => isNotOrganization(addressBook, organizationId))
        .filter(addressBook => isSelectedAddressBook(addressBook, selectedAddressBookName))
        .filter(addressBook => isLegacySharedAddressBook(addressBook, organizationId)),
    }
  }

  private getAddressBooks = (organizationId?: string): Promise<AddressBooks> =>
    authenticatedFetch<AddressBooks>(`${this.baseUrl()}/addressbooks`, { credentials: undefined })
      .then<AddressBooks>(this.handleErrorAndParseJson)
      .then(addressBooks =>
        organizationId ? this.getSelectedOrganizationAddressBooks(addressBooks, organizationId) : addressBooks,
      )

  getPersonalAddressBookId = async () => {
    if (useContactsApi()) {
      return await getContactsApi().getPersonalAddressBookId()
    }
    logNotUsingContactsTeamApi('getPersonalAddressBookId')
    const addressBooks = await this.getAddressBooks()
    const personalAddressBook = addressBooks.items?.filter(item => item.providerName === ProviderName.PERSONAL) ?? []
    return personalAddressBook[0]?.id ?? ''
  }

  // TODO: may be irrelevant when JIP provides an endpoint to fetch all contacts
  /**
   * @summary Retrieves a collection of `contacts` from the specified `addressbook`
   * @param addressBookId
   * @param queryParameters
   * @returns A list of `contacts`
   */
  private getContactsInAddressBook = (addressBookId: string, queryParams: ContactsQuery): Promise<Contacts> => {
    const url = new URL(`${this.baseUrl()}/addressbooks/${addressBookId}/contacts`)
    url.search = new URLSearchParams(Object.entries(queryParams)).toString()

    return authenticatedFetch(url.toString(), { credentials: undefined }).then(this.handleErrorAndParseJson)
  }

  getContacts = async (organizationId?: string): Promise<readonly Contact[]> => {
    if (useContactsApi()) {
      return await getContactsApi().getContacts(organizationId)
    }
    logNotUsingContactsTeamApi('getContacts')
    const { items } = await this.getAddressBooks(organizationId)

    const paginateThrough = async (addressBookId: string, pageSize: number, page = 0): Promise<readonly Contact[]> => {
      const { items } = await this.getContactsInAddressBook(addressBookId, { page, pageSize })

      if (items.length) {
        return items.concat(await paginateThrough(addressBookId, pageSize, page + 1))
      }

      return items
    }

    const {
      Office365 = [],
      Personal = [],
      Office365Enterprise = [],
      Enterprise = [],
      Organization = [],
      Shared = [],
    } = (
      await Promise.all(
        (items ?? [])
          .filter((item): item is Required<AddressBook> => !!item.id)
          .map(
            async ({ id, suggestedPageSize, providerName }) =>
              await paginateThrough(id, suggestedPageSize).then(contacts => ({ providerName, contacts })),
          ),
      )
    ).reduce(
      (accumulator, { providerName, contacts }) => {
        accumulator[providerName] = contacts
        return accumulator
      },
      {} as Record<ProviderName, readonly Contact[]>,
    )

    const deduplicatedContacts = deduplicateContacts([
      ...Office365,
      ...Office365Enterprise,
      ...Enterprise,
      ...Organization,
    ])

    return [...deduplicatedContacts, ...Personal, ...Shared]
  }

  /**
   * Searches Contact by Organization Id.
   * @param organizationId Organization ID associated with the user
   * @param userKey Identifier for the User.
   * @returns The corresponding contact, or empty if cannot be found.
   */
  lookup = (organizationId: string, userKey: string, pageSize = 500): Promise<Contacts> => {
    if (useContactsApi()) {
      return getContactsApi().lookup(organizationId, userKey, pageSize)
    }
    logNotUsingContactsTeamApi('lookup')
    const url = new URL(`${this.baseUrl()}/lookup/user`)

    url.search = new URLSearchParams({
      organizationId,
      userKey,
      pageSize: pageSize.toString(),
    }).toString()

    return authenticatedFetch(url.toString(), { credentials: undefined }).then(this.handleErrorAndParseJson)
  }

  /**
   * Searches Contact by Account Key.
   * Note: The account key is used to resolve the Organization ID. The latter is the actual parameter of the search.
   *
   * @param accountKey Account Key associated with the user
   * @param userKey Identifier for the User
   * @returns The corresponding contact, or empty if cannot be found.
   */
  lookupByAccount = (accountKey: string, userKey: string, pageSize = 500): Promise<Contacts> => {
    if (useContactsApi()) {
      return getContactsApi().lookupByAccount(accountKey, userKey, pageSize)
    }
    logNotUsingContactsTeamApi('lookupByAccount')
    const url = new URL(`${this.baseUrl()}/lookup/user`)

    url.search = new URLSearchParams({
      accountKey,
      userKey,
      pageSize: pageSize.toString(),
    }).toString()

    return authenticatedFetch(url.toString(), { credentials: undefined }).then(this.handleErrorAndParseJson)
  }

  lookupByPhoneNumbers = (phoneNumbers: readonly string[], pageSize = 500): Promise<Contacts> => {
    if (useContactsApi()) {
      return getContactsApi().lookupByPhoneNumbers(phoneNumbers, pageSize)
    }
    logNotUsingContactsTeamApi('lookupByPhoneNumbers')
    const emptyContactInfoObject = { items: [] }

    const API_PHONE_NUMBERS_QUERY_LIMIT = 25

    if (phoneNumbers.length === 0) {
      console.error('shell.contacts.lookupByPhoneNumbers: At least one phone number is required')
      return Promise.resolve(emptyContactInfoObject)
    }

    const isTooManyPhoneNumbers = phoneNumbers.length > API_PHONE_NUMBERS_QUERY_LIMIT

    let phoneNumbersToRequest = phoneNumbers

    if (isTooManyPhoneNumbers) {
      console.warn(
        `shell.contacts.lookupByPhoneNumbers: Maximum ${API_PHONE_NUMBERS_QUERY_LIMIT} phone numbers per request. The current result is the first ${API_PHONE_NUMBERS_QUERY_LIMIT} phone numbers submitted.`,
      )
      phoneNumbersToRequest = phoneNumbers.slice(0, API_PHONE_NUMBERS_QUERY_LIMIT)
    }

    const url = new URL(`${this.baseUrl()}/lookup`)

    const searchParam = new URLSearchParams({
      pageSize: pageSize.toString(),
    })
    phoneNumbersToRequest.forEach(number => {
      searchParam.append('q', number)
    })

    url.search = searchParam.toString()

    return authenticatedFetch(url.toString(), { credentials: undefined }).then(this.handleErrorAndParseJson)
  }

  search = (query: string, pageSize = 500, organizationId?: string): Promise<Contacts> => {
    if (useContactsApi()) {
      return getContactsApi().search(query, pageSize, organizationId)
    }
    logNotUsingContactsTeamApi('search')
    const url = new URL(`${this.baseUrl()}/search`)

    const searchParamsInit = {
      organizationId,
      q: query,
      pageSize: pageSize.toString(),
    }

    const searchQueryString = convertObjectToQueryString(searchParamsInit)

    url.search = searchQueryString

    return authenticatedFetch(url.toString(), { credentials: undefined }).then(this.handleErrorAndParseJson)
  }

  getContact = (addressBookId: string, contactId: string): Promise<Contact> => {
    if (useContactsApi()) {
      return getContactsApi().getContact(addressBookId, contactId)
    }
    logNotUsingContactsTeamApi('getContact')
    const url = new URL(`${this.baseUrl()}/addressbooks/${addressBookId}/contacts/${contactId}`)

    return authenticatedFetch(url.toString(), { credentials: undefined }).then(this.handleErrorAndParseJson)
  }

  createContact = (
    addressBookId: string,
    contact: Omit<Contact, 'id' | 'addressBookId' | 'providerName'>,
  ): Promise<Contact> => {
    if (useContactsApi()) {
      return getContactsApi().createContact(addressBookId, contact)
    }
    logNotUsingContactsTeamApi('createContact')

    return authenticatedFetch(`${this.baseUrl()}/addressbooks/${addressBookId}/contacts`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(contact),
      credentials: undefined,
    }).then(this.handleErrorAndParseJson)
  }

  deleteContact = (contact: Contact): Promise<void> => {
    if (useContactsApi()) {
      return getContactsApi().deleteContact(contact)
    }
    logNotUsingContactsTeamApi('deleteContact')
    return authenticatedFetch(`${this.baseUrl()}/addressbooks/${contact.addressBookId}/contacts/${contact.id}`, {
      method: 'DELETE',
      credentials: undefined,
    }).then(this.handleErrorAndParseJson)
  }

  updateContact = (contact: Contact): Promise<Contact> => {
    if (useContactsApi()) {
      return getContactsApi().updateContact(contact)
    }
    logNotUsingContactsTeamApi('updateContact')
    return authenticatedFetch(`${this.baseUrl()}/addressbooks/${contact.addressBookId}/contacts/${contact.id}`, {
      method: 'PUT',
      body: JSON.stringify(contact),
      headers: { 'content-type': 'application/json' },
      credentials: undefined,
    }).then(this.handleErrorAndParseJson)
  }

  getMeetingProfile = (externalKey: string | undefined): Promise<readonly MeetingProfile[]> => {
    if (useContactsApi()) {
      return getContactsApi().getMeetingProfile(externalKey)
    }
    logNotUsingContactsTeamApi('getMeetingProfile')
    const url = new URL(`${this.meetingBaseUrl()}/search/profiles?userKey=${externalKey}`)

    return fetch(url.toString()).then(this.handleErrorAndParseJson)
  }

  updateContactFavoriteStatus = (addressBookId: string, contactId: string, favoriteStatus: boolean) => {
    if (useContactsApi()) {
      return getContactsApi().updateContactFavoriteStatus(addressBookId, contactId, favoriteStatus)
    }
    logNotUsingContactsTeamApi('updateContactFavoriteStatus')
    console.warn(
      'updateContactFavoriteStatus of the contacts api is deprecated. use "setFavoriteStatus" command on the contacts namespace instead',
    )
    return authenticatedFetch(`${this.baseUrl()}/addressbooks/${addressBookId}/contacts/${contactId}`, {
      method: 'PATCH',
      body: JSON.stringify([
        {
          op: 'replace',
          path: '/favorite',
          value: favoriteStatus,
        },
      ]),
      headers: { 'content-type': 'application/json' },
      credentials: undefined,
    }).then(this.handleErrorAndParseJson)
  }
}
