import type { ObjectValidator } from '../assert-helpers'
import { assertIsExpectedObject } from '../assert-helpers'
import { getFromSessionStorage, popFromSessionStorage } from '../dom-helpers'
import { getShellLogger } from '../logger'

/**
 * parse JSON and assert it's the correct shape.
 *
 * @param maybeJSON the value that is possibly JSON
 * @param validator function against which the parsed JSON is checked to make sure it's the expected
 * shape at run time
 * @return T | null - returns T if the JSON is valid, null if the the value is not found, the value is not valid JSON or
 * if the parsed JSON is not the correct shape.
 */
export const parseAndAssertJSON = <T>(maybeJSON: string, validator: ObjectValidator): T | null => {
  try {
    const parsedJSON = JSON.parse(maybeJSON)
    assertIsExpectedObject<T>(parsedJSON, validator)
    return parsedJSON
  } catch (e) {
    getShellLogger().error('failed to parse JSON', e)
    return null
  }
}

const getJSONfromStorage =
  (getterFunction: (key: string) => string | null) =>
  <T>(key: string, objectValidator: ObjectValidator) => {
    const maybeJSON = getterFunction(key)
    return typeof maybeJSON === 'string' ? parseAndAssertJSON<T>(maybeJSON, objectValidator) : null
  }

export const getJSONfromSessionStorage = getJSONfromStorage(getFromSessionStorage)
export const popJSONfromSessionStorage = getJSONfromStorage(popFromSessionStorage)

export const safeParseJSON = <T>(maybeJSON: string | unknown, defaultValue: T): T => {
  let value = defaultValue

  if (typeof maybeJSON === 'string') {
    try {
      value = JSON.parse(maybeJSON)
    } catch {
      getShellLogger().error('SafeParseJSON: failed to parse JSON', maybeJSON)
    }
  }
  return value
}
