import { getShellLogger } from '../../common/logger'
import { environment, LocalStorageKeys } from '../../environments'
import { getFromLocalStorage, getLocationPathname, setToLocalStorage } from '../../common/dom-helpers'
import type {
  BrandingMandatoryMembers,
  FeatureFlags,
  LaunchDarklyPayload,
  UnleashResponse,
  UnleashToggle,
} from './models'
import { LaunchDarklyVariations, BrandVariationKeys, UnleashPayloadType } from './models'
import { getLDAnonymousUUIDKey, isInternal, Product } from './utils'
import {
  UNLEASH_START_MARK,
  UNLEASH_DONE_MARK,
  LAUNCHDARKLY_START_MARK,
  LAUNCHDARKLY_DONE_MARK,
  measureUnleashSetupLoadingTime,
  measureLaunchDarklySetupLoadingTime,
} from './performance'
import { fetchWithTimeout } from '../../common/fetch-utils'
import { safeParseJSON } from '../../common/helpers/json'

const FLAGS_SERVICES_TIMEOUT = 5 * 1000 // 5 seconds

let cachedLDFeatureFlags: LaunchDarklyPayload | undefined
let cachedFeatureFlags: FeatureFlags | undefined

const setCachedLDFeatureFlags = (flags: LaunchDarklyPayload | undefined) => {
  const overrideValues = getFromLocalStorage(LocalStorageKeys.goto_launch_darkly_override)
  const launchDarklyOverrideValues = safeParseJSON<Partial<LaunchDarklyPayload>>(overrideValues, {})
  cachedLDFeatureFlags = mergeFlags(flags, launchDarklyOverrideValues)
}

const getCachedLDFeatureFlags = () => cachedLDFeatureFlags

// Temporary function to log the error trace for the deprecated functions
const logErrorTrace = (message: string, err: Error) => {
  const stackRaw = err.stack?.split('\n').slice(1)
  const stackTrimmed = stackRaw?.map(line => line.trimStart())
  getShellLogger().log(`${message} Path: ${getLocationPathname()}. Stack: ${stackTrimmed}`)
}

/**
 * @deprecated migrate your flags to Unleash and use getFeatureFlags instead
 * Returns launchdarkly flags if there are any otherwise undefined
 * @example
 *  const flags = window.shell.featureFlags.getLaunchDarklyFlags()
 *  if (!flags) {
 *   // handle me
 *  }
 */
export const getLaunchDarklyFlags = <T = LaunchDarklyPayload>() => {
  const err = new Error()
  logErrorTrace(`getLaunchDarklyFlags called`, err)

  const cachedFlags = getCachedLDFeatureFlags()
  if (cachedFlags) {
    return cachedFlags as T
  }
  const values = getFromLocalStorage(LocalStorageKeys.goto_launch_darkly)

  if (!values) {
    return undefined
  }
  const launchDarklyValues = safeParseJSON<T>(values, {} as T)

  const overrideValues = getFromLocalStorage(LocalStorageKeys.goto_launch_darkly_override)
  const launchDarklyOverrideValues = safeParseJSON<Partial<T>>(overrideValues, {})
  const mergedLaunchDarklyValues = mergeFlags<T>(launchDarklyValues, launchDarklyOverrideValues)
  return mergedLaunchDarklyValues
}

/**
 * @deprecated migrate your flag to Unleash and use getFeatureFlagValue instead
 */
export const getLaunchDarklyFlag = <U extends keyof T, T = LaunchDarklyPayload>(flagName: U) => {
  getShellLogger().log(`getLaunchDarklyFlag called with flagName=${flagName.toString()}`)

  return getLaunchDarklyFlags<T>()?.[flagName]
}

export const setLaunchDarklyFlags = <T = LaunchDarklyPayload>(flags: T | null) =>
  setToLocalStorage(LocalStorageKeys.goto_launch_darkly, JSON.stringify(flags ?? ''))

/**
 * This is the branding default values.
 * This is useful in situations where a URL for an image is unreachable so we need to fall back to the URL of the image that is bundled with the app.
 */
export const brandDefaultValues: BrandingMandatoryMembers = {
  [BrandVariationKeys.FAVICON_ID]: 'goto/favicon.ico',
  [BrandVariationKeys.HOME_PAGE_LOGO_ID]: 'goto/darkmode/gotoLogo.svg',
  [BrandVariationKeys.JIVE_BRAND_NAME]: 'GoTo',
  [BrandVariationKeys.LOADING_LOGO_ID]: 'goto/loadingLogo.png',
  [BrandVariationKeys.ONBOARDING_COMPUTER_CALENDAR_ID]: 'gotoRebrand/onboardingComputerCalendar.svg',
  [BrandVariationKeys.ONBOARDING_COMPUTER_ID]: 'goto/onboardingComputer.svg',
  [BrandVariationKeys.SHELL_BRAND_NAME]: 'GoTo',
  [BrandVariationKeys.TOPBAR_LOGO_ID]: 'goto/gotoLogo.svg',
  [BrandVariationKeys.WELCOME_LOGO_ID]: 'goto/gotoLogo.svg',
  [BrandVariationKeys.CHAMELEON_THEME_PROVIDER_BRAND]: 'rebranding2021',
}

export const DEFAULT_FLAGS: LaunchDarklyPayload = {
  [LaunchDarklyVariations.PKCE_REFRESH_TOKENS]: {
    enabled: false,
    maxRefreshTokens: 0,
  },
  [LaunchDarklyVariations.SHELL_ENABLE_DARKMODE]: false,
  [LaunchDarklyVariations.BRANDING]: { ...brandDefaultValues },
  [LaunchDarklyVariations.SHELL_APPLICATION_SWITCHER]: false,
  [LaunchDarklyVariations.SHELL_NOTIFICATION_SETTINGS]: false,
  [LaunchDarklyVariations.SHELL_AVATAR_POPOVER_HELP_MENU_ITEM]: false,
  [LaunchDarklyVariations.SHELL_HOTKEY_SETTINGS]: false,
  [LaunchDarklyVariations.SHELL_LANGUAGE_SETTINGS]: false,
  [LaunchDarklyVariations.SHELL_ADVANCED_PRESENCE_BUSY]: false,
  [LaunchDarklyVariations.SHELL_TOPBAR_ACCOUNT_SWITCHER]: false,
  [LaunchDarklyVariations.SHELL_RESOLVE_USER_ACCESS_ATTACHMENT]: false,
  [LaunchDarklyVariations.SHELL_NOTIFICATION_CHANNEL_V2]: false,
  [LaunchDarklyVariations.SHELL_TRACK_NC_DISCONNECT_BANNER]: false,
  [LaunchDarklyVariations.SHELL_DOCK_BADGE]: false,
  [LaunchDarklyVariations.SHELL_GLOBAL_SEARCH_MOCK_API_RESPONSE]: false,
  [LaunchDarklyVariations.SHELL_PAGES_OPTIMIZATION]: false,
  [LaunchDarklyVariations.VOICE_MULTILINE_REGISTRATION]: false,
  [LaunchDarklyVariations.VOICE_REST_PHONE]: true,
}

interface SetupLaunchDarklyFlagParams {
  readonly numberOfAccounts?: number
  readonly userName?: string
  readonly externalUserKey?: string
  readonly accountKey?: string
  readonly pbxId?: string
  readonly entitlements?: readonly string[]
  readonly licenses?: readonly GoToLicense[]
  readonly allAccountKeys?: readonly string[]
  readonly deviceBrand?: string
  readonly deviceModel?: string
  readonly osName?: string
  readonly osVersion?: string
  readonly hostname?: string
  readonly pathname?: string
  readonly partnerAccountKey?: string
  readonly userCreationDate?: string
  readonly currentAccountName?: string
}

interface FeatureFlagParams extends SetupLaunchDarklyFlagParams {
  readonly email: string | undefined
  readonly licenseKeys: readonly string[]
  readonly product: string
  readonly roles: readonly string[]
}

export interface GoToLicense {
  readonly channel: string
  readonly description: string
  readonly enabled: boolean
  readonly key: string
  readonly products: readonly string[]
  readonly type: string
  readonly accountKey: string
}

export interface DeviceInfo {
  readonly deviceBrand?: string
  readonly deviceModel?: string
}

/**
 * Calls LaunchDarkly's API and stores the flags using setLaunchDarklyFlags
 * If, for any reason, the call fails, then it will use the default values for the flags
 */
export const setupLaunchDarklyFlags = async ({
  userName,
  externalUserKey,
  accountKey,
  pbxId,
  entitlements,
  licenses,
  numberOfAccounts,
  allAccountKeys,
  deviceBrand,
  deviceModel,
  osName,
  osVersion,
  hostname,
}: SetupLaunchDarklyFlagParams) => {
  performance.mark(LAUNCHDARKLY_START_MARK)
  let flags = mergeFlags({ ...DEFAULT_FLAGS }, getLaunchDarklyFlags())

  try {
    const optionalValues = {
      externalUserKey,
      pbxId,
      accountKey,
      hostname,
      pathname: location.pathname,
      product: Product.WEB,
      deviceBrand,
      deviceModel,
      OSName: osName,
      OSVersion: osVersion,
      entitlements,
      licenses,
      numberOfAccounts,
      allAccountKeys,
    }
    const LDValues: { readonly [key: string]: string | readonly string[] } = Object.entries(optionalValues).reduce(
      (values: { [key: string]: string | readonly string[] }, value) => {
        if (value[1]) {
          values[value[0]] = String(value[1])
        }
        return values
      },
      {},
    )
    const key = accountKey ? accountKey : getLDAnonymousUUIDKey()
    /**
     * Grabbing fresh flags every time since they may have been turned off since last fetch
     */
    const newFlags = await fetchWithTimeout('https://api.jive.com/feature-flags/v1/launch-darkly', {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        anonymous: true,
        custom: { ...LDValues, internal: isInternal(userName) },
        key,
      }),
      timeoutInMS: FLAGS_SERVICES_TIMEOUT,
    }).then(response => response?.json() ?? {})
    flags = mergeFlags(flags, newFlags)
  } catch (e) {
    getShellLogger().error('feature flags error', e)
  }

  setLaunchDarklyFlags(flags)
  setCachedLDFeatureFlags(flags)
  performance.mark(LAUNCHDARKLY_DONE_MARK)
  measureLaunchDarklySetupLoadingTime()
}

const mergeObjects = <T>(original: T, overrides: Partial<T> | null | undefined): T => {
  const result: T = { ...original }
  if (overrides) {
    for (const key in overrides) {
      try {
        if (!!original && typeof original[key] === 'object' && typeof overrides[key] === 'object') {
          result[key] = mergeObjects(result[key], (overrides as T)[key])
        } else {
          result[key] = (overrides as T)[key]
        }
      } catch (e) {
        getShellLogger().error('merge error', e)
      }
    }
  }
  return result
}

const mergeFlags = <T = LaunchDarklyPayload>(original: T, overrides: Partial<T> | null | undefined): T =>
  mergeObjects<T>(original, overrides)

/**
 * Interface for accessing feature flags in the shell.
 */
export interface ShellFeatureFlagsAPIs {
  /**
   * @deprecated migrate your flags to Unleash and use getFeatureFlags instead
   */
  readonly getLaunchDarklyFlags: <T = LaunchDarklyPayload>() => T | undefined

  /**
   * @deprecated migrate your flag to Unleash and use getFeatureFlagValue instead
   */
  readonly getLaunchDarklyFlag: <U extends keyof T, T = LaunchDarklyPayload>(
    flagName: U,
  ) => NonNullable<T>[U] | undefined

  /**
   * @returns all the feature flags that are currently toggled on for the user
   * Note that flags that are toggled off will not be returned
   */
  readonly getFeatureFlags: () => FeatureFlags | undefined

  /**
   * @param key name of the flag being queried
   * @param defaultValue the default value the consumer wants to return if no value is returned for the given flag
   * @returns the value of the given flag, or the default value sent if no value is returned
   * Note that flags that are toggled off will not be returned. Since we cannot know if a queried flag that returns no value
   * is simply toggled off or does not exist, we cannot support a default value of true.
   */
  readonly getFeatureFlagValue: <T>(key: string, defaultValue?: T) => T | undefined
}

/**
 * Retrieves all the feature flags from Unleash.
 * @returns Promise Response for the flags
 */
export const setupUnleashFlags = async (featureFlagParams: FeatureFlagParams): Promise<void> =>
  await new Promise((resolve, reject) => {
    performance.mark(UNLEASH_START_MARK)
    const params = Object.entries(featureFlagParams).reduce(
      (params, [key, value]) =>
        // Unleash does not currently support an array of objects which is why we are omitting the licenses value for now
        value !== undefined && key !== 'licenses'
          ? params + `properties[${key}]=${encodeURIComponent(value)}&`
          : params,
      '',
    )
    const headers = new Headers({
      Authorization: environment().unleashAccessToken,
    })

    const url = `https://api.jive.com/feature-flags-edge/v1/api/frontend?${params}`

    let responseStatus = 200
    let responseText = ''

    fetchWithTimeout(url, {
      headers,
      timeoutInMS: FLAGS_SERVICES_TIMEOUT,
    })
      .then(async response => {
        performance.mark(UNLEASH_DONE_MARK)
        measureUnleashSetupLoadingTime()
        responseStatus = response.status
        responseText = await response.text()
        return JSON.parse(responseText)
      })
      .then((response: UnleashResponse | undefined) => {
        if (response) {
          const flags = convertUnleashFlags(response.toggles)
          if (flags) {
            setFeatureFlags(flags)
            cachedFeatureFlags = addFeatureFlagsOverride(flags)
          }
        }
        resolve()
      })
      .catch(e => {
        getShellLogger().error('Could not retrieve Unleash feature flags', {
          error: e,
          url,
          responseText,
          responseStatus,
        })
        reject(e)
        return undefined
      })
  })

const convertUnleashFlags = (flags: readonly UnleashToggle[]): FeatureFlags | undefined => {
  if (!flags?.length) {
    return undefined
  }
  const featureFlags = flags.reduce((convertedFlags, unleashFlag) => {
    const payload = unleashFlag.variant.payload
    if (payload) {
      if (payload.type === UnleashPayloadType.JSON) {
        try {
          const value = JSON.parse(payload.value)
          convertedFlags[unleashFlag.name] = value
        } catch (e) {
          getShellLogger().error('Invalid json format, could not be parsed', e)
        }
      }
      if (payload.type === UnleashPayloadType.STRING) {
        convertedFlags[unleashFlag.name] = payload.value
      }
    } else {
      convertedFlags[unleashFlag.name] = true
    }
    return convertedFlags
  }, {} as FeatureFlags)
  return featureFlags
}

const setFeatureFlags = (flags: FeatureFlags) => {
  setToLocalStorage(LocalStorageKeys.gotoAppFeatureFlags, JSON.stringify(flags ?? ''))
}

const getCachedFeatureFlags = () => cachedFeatureFlags

/**
 * @returns all the feature flags that are currently toggled on for the user
 * Note that flags that are toggled off will not be returned
 */
export const getFeatureFlags = (): FeatureFlags | undefined => {
  const cachedFlags = getCachedFeatureFlags()
  if (cachedFlags) {
    return cachedFlags
  }
  const values = getFromLocalStorage(LocalStorageKeys.gotoAppFeatureFlags)
  if (!values) {
    return undefined
  }

  const featureFlagsValues = safeParseJSON<FeatureFlags>(values, {})
  const mergedFeatureFlags = addFeatureFlagsOverride(featureFlagsValues)

  return mergedFeatureFlags
}

const addFeatureFlagsOverride = (featureFlagsValues: FeatureFlags): FeatureFlags => {
  const overrideValues = getFromLocalStorage(LocalStorageKeys.gotoAppFeatureFlagsOverride)
  const featureFlagsOverride = safeParseJSON<Partial<FeatureFlags>>(overrideValues, {})
  const mergedFeatureFlags = mergeFlags<FeatureFlags>(featureFlagsValues, featureFlagsOverride)

  return mergedFeatureFlags
}

/**
 * @param key name of the flag being queried
 * @param defaultValue the default value the consumer wants to return if no value is returned for the given flag
 * @returns the value of the given flag, or the default value sent if no value is returned
 * Note that flags that are toggled off will not be returned. Since we cannot know if a queried flag that returns no value
 * is simply toggled off or does not exist, we cannot support a default value of true.
 */
export const getFeatureFlagValue = <T>(key: string, defaultValue?: T): T | undefined => {
  const flagValue = getFeatureFlags()?.[key] as T
  if (flagValue !== undefined) {
    return flagValue
  }
  if (defaultValue === true) {
    getShellLogger().error('Setting a feature flag default value to true is currently not supported')
    return undefined
  } else {
    return defaultValue
  }
}

export const shellFeatureFlagsAPI = {
  getLaunchDarklyFlags,
  getLaunchDarklyFlag,
  getFeatureFlags,
  getFeatureFlagValue,
}
