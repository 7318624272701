import type { ShellHook } from './models'
import {
  type DndSource,
  DndSources,
  DndStatusSource,
  type PresenceEvents,
  PresenceServiceNamespace,
  UserAppearance,
  type UserAppearanceAndDNDUpdated,
  UserDoNotDisturb,
} from '../../services/presence'
import { getEventBus, type Listener } from '../../services/namespaces'
import { getShellApiInstance } from '../../common/shell-api-helpers'
import { getTranslation } from '../../services/i18n/i18nUtils'
import type { SnackbarNotificationInstance } from '../../services/display-notification'
import { getUserAppearanceTranslationKey } from '../../common/presence-helpers'
import { navigateToUrl } from '@goto/shell-utils'
import { SCHEDULE_SETTINGS_ROUTE } from '../../common/routes'
import { onWindowUnload } from '../../common/helpers/window'
import { cloneDeep } from '../helpers/clone'

export const initializeScheduleChangedSnackbar: ShellHook = async () => {
  const { userAppearanceAndDNDUpdate } = getEventBus().subscribeTo<
    typeof PresenceServiceNamespace,
    typeof PresenceEvents
  >(PresenceServiceNamespace)

  let currentUserAppearanceAndDND: UserAppearanceAndDNDUpdated | null = null

  const userAppearanceAndDNDUpdateHandler: Listener<UserAppearanceAndDNDUpdated> = userAppearanceAndDNDUpdated => {
    // When the app first loads, we want to avoid showing a snackbar notification and simply save the current appearance
    if (currentUserAppearanceAndDND === null) {
      currentUserAppearanceAndDND = cloneDeep(userAppearanceAndDNDUpdated)
      return
    }

    // Iterate each source and stop if a source shows a notification.
    // This list is ordered by priority so if a source shows a notification, we have to stop iterating to show only 1 notification.
    for (const dndSource of userAppearanceAndDNDUpdated.doNotDisturbDetails?.dndSources || []) {
      if (showNotification(dndSource, userAppearanceAndDNDUpdated)) {
        break // Exit the loop if showNotification returns true
      }
    }
  }

  userAppearanceAndDNDUpdate.on(userAppearanceAndDNDUpdateHandler)
  onWindowUnload(() => {
    userAppearanceAndDNDUpdate.removeListener(userAppearanceAndDNDUpdateHandler)
  })
}

/**
 * We have an order to follow when we show notification which depend on a list of Sources we receive from an event
 * The sources are in a list, with the highest priority source at the beginning of the list.
 * If a source has a status of "NONE", the next source is considered.
 *
 * For each source, the status tells if this source is set or not.
 *    ON: Source is requesting to be on Do Not Disturb
 *    OFF: The source is requesting to be OFF Do Not Disturb
 *    NONE: The source is not set, sources with lower priority will be considered
 */
const showNotification = (dndSource: DndSource, userAppearanceAndDNDUpdated: UserAppearanceAndDNDUpdated): boolean => {
  switch (dndSource.source) {
    case DndSources.USER_SET:
      const workPeriodSource = userAppearanceAndDNDUpdated.doNotDisturbDetails?.dndSources.find(
        dndSource => dndSource.source == DndSources.WORK_PERIOD,
      )
      const oneTimePeriodSource = userAppearanceAndDNDUpdated.doNotDisturbDetails?.dndSources.find(
        dndSource => dndSource.source == DndSources.ONE_TIME_PERIOD,
      )
      // When manual override is considered, we have to look if a work schedule is Enabled before showing the notification
      // Without a work schedule, there is no notification for a manual override
      if (
        dndSource.status !== DndStatusSource.NONE &&
        (userAppearanceAndDNDUpdated.userAppearance !== UserAppearance.NONE ||
          userAppearanceAndDNDUpdated.userDoNotDisturb === UserDoNotDisturb.DO_NOT_DISTURB) &&
        (workPeriodSource?.status !== DndStatusSource.NONE || oneTimePeriodSource?.status !== DndStatusSource.NONE)
      ) {
        showManualOverrideScheduleNotification(
          userAppearanceAndDNDUpdated.userDoNotDisturb,
          userAppearanceAndDNDUpdated.userAppearance,
        )
      }
      return dndSource.status !== DndStatusSource.NONE
    case DndSources.ONE_TIME_PERIOD:
      if (dndSource.status === DndStatusSource.ON) {
        showCustomHoursScheduleNotification(true)
        return true
      } else if (dndSource.status === DndStatusSource.OFF) {
        showCustomHoursScheduleNotification(false)
        return true
      }
      return false
    case DndSources.WORK_PERIOD:
      if (dndSource.status === DndStatusSource.ON) {
        showWorkScheduleNotification(true)
        return true
      } else if (dndSource.status === DndStatusSource.OFF) {
        showWorkScheduleNotification(false)
        return true
      }
      return false
    default:
      return false
  }
}

const showWorkScheduleNotification = (isRequestDnd: boolean) => {
  const title = isRequestDnd ? getTranslation("It's after hours") : getTranslation('Your work hours have started')
  const message = isRequestDnd
    ? getTranslation('You’re set to do not disturb')
    : getTranslation('Do not disturb is off')

  getShellApiInstance().display.snackbar({
    id: 'work-hours-schedule-notification',
    title,
    message,
  })
}

const showCustomHoursScheduleNotification = (isRequestDnd: boolean) => {
  const title = isRequestDnd
    ? getTranslation(`Your custom hours have started`)
    : getTranslation('Your custom hours have ended')
  const message = isRequestDnd
    ? getTranslation('You’re set to do not disturb')
    : getTranslation('Do not disturb is off')
  const actions = isRequestDnd
    ? [
        {
          label: getTranslation('View schedule'),
          handler() {
            navigateToUrl(SCHEDULE_SETTINGS_ROUTE)
            closeSnackbarNotifications(snackbarNotification)
          },
        },
        {
          label: getTranslation('Close'),
          handler() {
            closeSnackbarNotifications(snackbarNotification)
          },
        },
      ]
    : []

  const snackbarNotification = getShellApiInstance().display.snackbar({
    id: 'custom-work-hours-schedule-notification',
    title,
    message: message,
    actions: actions,
  })
}

const showManualOverrideScheduleNotification = (
  userDoNotDisturb?: UserDoNotDisturb,
  userAppearance?: UserAppearance,
) => {
  const getStatus =
    userDoNotDisturb === UserDoNotDisturb.DO_NOT_DISTURB
      ? getTranslation('Do not disturb')
      : getTranslation(getUserAppearanceTranslationKey(userAppearance))
  getShellApiInstance().display.snackbar({
    id: 'manual-override-schedule-notification',
    title: getTranslation(`Status set to {{status}}`, {
      status: getStatus,
    }),
    message: getTranslation('To return to your work schedule, set your status to auto-update'),
  })
}

const closeSnackbarNotifications = (currentSnackbarNotification: SnackbarNotificationInstance) => {
  currentSnackbarNotification.close()
}
