import { ShellUserAuthorityContextPermissions } from '../../core/models'
import { getShellApiInstance } from '../../common/shell-api-helpers'
import type { ContactsNamespace, ShellContactAPI } from '@goto/contacts-definitions'

/* istanbul ignore next */
export const isInvictus = () => {
  const hasPermission = getShellApiInstance().user.hasPermissions([
    ShellUserAuthorityContextPermissions.VIEW_SHARED_CONTACTS,
  ])

  return hasPermission
}

export const useContactsApi = () => getShellContactsServicesCommand().isHandled()

export const getContactsApi = () => getShellContactsServicesCommand().execute() as ShellContactAPI

const getShellContactsServicesCommand = () =>
  getShellApiInstance().namespaces.retrieve<ContactsNamespace>('contacts-namespace').commands.getShellContactsServices
