import { asyncTryAndReturn } from '../../common/catcher'
import { getFromLocalStorage, removeFromLocalStorage, setToLocalStorage } from '../../common/dom-helpers'
import { type AccountAndPbxInfo } from '../account/models'
import { getAccountKey, getJifUser, getPrincipal } from '../identity'
import { getPbxFeatureFlags } from '../phone-system/pbx-feature-flags'
import { type ContextApi, type Pbx, type PbxInfoParams, type User, type PbxData, type PbxContext, PbxState } from './models'

/**
 * The GTC experience is currently the only one that tracks the user's selected PBX using a local storage
 * key/value. However some other experiences (Contact Center) will need to know the PBX id when the Shell
 * bootstraps, independently of any other experience loading.
 *
 * This is done by first checking whether a pbxId is saved in local storage from GTC.
 * If no value from GTC is available, we check the ShellLocalContext value in local storage.
 * If we find either of the above values in local storage, we perform an API call to check that the value is in the approved list from the Jif Api
 *
 * If neither of the above is available, we take the first PBX id from the list returned by the Jif API.
 */

export let userAccountsWithPBx: readonly User[]

//TODO: add error handling for this function to avoid stalling out Shell start
//https://jira.ops.expertcity.com/browse/SCORE-1685
export const getPbxId = (
  externalUserKey: string,
  pbxListFromJifApi: readonly Pbx[],
): string | undefined => {
  const gtcSelectedDeviceLocalStorageKey = formatGTCSelectedDeviceLocalStorageKey(externalUserKey)
  const gtcSelectedDevicePbxId = getPbxIdFromGTCSelectedDevice(gtcSelectedDeviceLocalStorageKey)
  /**
   * Two API calls are necessary, as the Jif API returns most of the data needed, however,
   * the state ('ACTIVE' vs 'DEACTIVATED') is only available from the Principal API
   */

  const pbxIdListFromJifApi = pbxListFromJifApi.map((pbx: Pbx) => pbx.id)
  const localStoragePbxIdIsValid = (pbxId: string) =>
    pbxIdListFromJifApi.includes(pbxId) && isValidPbx(pbxId, pbxListFromJifApi)

  if (gtcSelectedDevicePbxId && localStoragePbxIdIsValid(gtcSelectedDevicePbxId)) {
    return gtcSelectedDevicePbxId
  }

  const shellPbxId = getPbxIdFromShellLocalStorage(externalUserKey)

  if (shellPbxId && localStoragePbxIdIsValid(shellPbxId)) {
    return shellPbxId
  }

  return pbxIdListFromJifApi.find((pbxId: string) => isValidPbx(pbxId, pbxListFromJifApi))
}

export const getPbxData = async (email: string, externalUserKey: string): Promise<PbxData> => {
  const pbxDataPromises: Promise<any>[] = [
    asyncTryAndReturn({ func: () => getAccountsWithPbxFromPrincipalApi(email), defaultReturn: undefined }),
    asyncTryAndReturn({ func: () => getPbxListFromJifApi(email), defaultReturn: undefined }),
  ]
  const cachedPbxId = getPbxIdFromShellLocalStorage(externalUserKey) ?? ''

  if (cachedPbxId) {
    pbxDataPromises.push(asyncTryAndReturn({ func: () => getPbxFeatureFlags(cachedPbxId), defaultReturn: undefined }))
  }

  let [pbxListFromPrincipalApi, pbxListFromJifApi, pbxFlags] = await Promise.all(pbxDataPromises)
  const pbxId = getPbxId(externalUserKey, pbxListFromJifApi) ?? ''
  const pbxUserId = getPbxUserId(pbxId, pbxListFromJifApi) ?? ''
  const accountKey = getAccountKey(externalUserKey, pbxListFromPrincipalApi, pbxId) ?? ''
  if (pbxId !== cachedPbxId) {
    pbxFlags = await getPbxFeatureFlags(pbxId)
  }

  if (pbxFlags) {
    pbxFlags = Object.entries(pbxFlags).reduce(
      // convert from { [flagName]: 'ENABLED' | 'DISABLED' } to { [flagName]: boolean }
      (flags, [flagName, setting]) => ({ ...flags, [flagName]: setting === 'ENABLED' }),
      {},
    )
  }

  return { accountKey, pbxId, pbxUserId, pbxFlags }
}

export const getPbxUserId = (pbxId: string | undefined, pbxListFromJifApi: readonly Pbx[]): string | undefined =>
  pbxListFromJifApi.find((pbx: Pbx) => pbx.id === pbxId)?.userInfo?.userId

export const formatGTCSelectedDeviceLocalStorageKey = (externalUserKey: string) =>
  `jiveweb_userSelectedDevice_${externalUserKey}`

export const formatShellContextLocalStorageKey = (id: string) => `goto-context_${id}`

export const getPbxIdFromGTCSelectedDevice = (key: string): string | undefined => {
  const gtcSelectedDeviceLocalStorageValue = getFromLocalStorage(key)

  if (gtcSelectedDeviceLocalStorageValue) {
    const gtcSelectedDevice = JSON.parse(gtcSelectedDeviceLocalStorageValue)
    return gtcSelectedDevice.pbx.id
  }
}

export const getPbxListFromJifApi = async (email: string): Promise<readonly Pbx[]> => {
  const jifUserData = await getJifUser(email)
  /**
   * When the API call is made for a user without g2c entitlement, it returns a 404 with an object.
   * For the moment, it made sense to set all properties below to optional in order to default to []
   * in case of a user without a pbx. Open to changing to a try/catch in the future?
   */

  return jifUserData?.data?.tenants?.pbxes ?? []
}

export const getAccountsWithPbxFromPrincipalApi = async (email: string): Promise<readonly User[]> => {
  const { users } = await getPrincipal(email)
  userAccountsWithPBx = users ?? []
  return users ?? []
}

export const pbxIsActive = (pbxList:readonly Pbx[], pbxId: string) =>
  pbxList.find(pbx => pbx.id === pbxId)?.state === PbxState.ACTIVE

const pbxHasExtensions = (pbxList: readonly Pbx[], pbxId: string) =>
  !!pbxList.find(pbx => pbx.id === pbxId)?.extensions?.length

export const isValidPbx = (pbxId: string, jifPbxList: readonly Pbx[]) =>
  pbxIsActive(jifPbxList, pbxId) && pbxHasExtensions(jifPbxList, pbxId)

const getContextFromShellLocalStorage = (externalUserKey: string): ContextApi | undefined => {
  const shellContextLocalStorageKey = formatShellContextLocalStorageKey(externalUserKey)
  const shellContextLocalStorageValue = getFromLocalStorage(shellContextLocalStorageKey)
  let shellContext
  try {
    shellContext = JSON.parse(shellContextLocalStorageValue ?? '')
    if (!shellContext || typeof shellContext !== 'object') {
      shellContext = {}
    }
  } catch {
    shellContext = {}
  }
  return shellContext
}

export const getAccountKeyFromShellLocalStorage = (externalUserKey: string): string | undefined => {
  const shellContextLocalStorage = getContextFromShellLocalStorage(externalUserKey)
  return shellContextLocalStorage?.account?.key
}

export const getPbxIdFromShellLocalStorage = (externalUserKey: string): string | undefined => {
  const shellContextLocalStorage = getContextFromShellLocalStorage(externalUserKey)
  return shellContextLocalStorage?.pbx?.id
}

export const setContextLocalStorageAccountKey = (externalUserKey: string, accountKey: string) => {
  const shellContextLocalStorageKey = formatShellContextLocalStorageKey(externalUserKey)
  const shellContextLocalStorage = getContextFromShellLocalStorage(externalUserKey)

  setToLocalStorage(
    shellContextLocalStorageKey,
    JSON.stringify({ ...shellContextLocalStorage, account: { key: accountKey } }),
  )
}

export const removeAccountAndPBXFromLocalStorage = (externalUserKey: string) => {
  removeGTCPbxIdFromLocalStorage(externalUserKey)
}

const removeGTCPbxIdFromLocalStorage = (externalUserKey: string) => {
  const gtcSelectedDeviceLocalStorageKey = formatGTCSelectedDeviceLocalStorageKey(externalUserKey)

  removeFromLocalStorage(gtcSelectedDeviceLocalStorageKey)
}
/**
 * Bypass the switchAccount event in order to not trigger the reload
 * @param accountSwitchBeginPayload
 */
export const setAccountAndPBXToLocalStorage = (accountSwitchBeginPayload: AccountAndPbxInfo) => {
  setContextLocalStorageAccountKey(accountSwitchBeginPayload.externalUserKey, accountSwitchBeginPayload.accountKey)
  setContextLocalStoragePbxInfo({
    externalUserKey: accountSwitchBeginPayload.externalUserKey,
    pbxId: accountSwitchBeginPayload.pbxId,
    pbxUserId: accountSwitchBeginPayload.pbxUserId,
  })
}

export const getAccountAndPBXFromLocalStorage = (externalUserKey: string): AccountAndPbxInfo => {
  const context = getContextFromShellLocalStorage(externalUserKey)
  const pbxInfo = getContextLocalStoragePbxInfo(externalUserKey)

  return {
    accountKey: context?.account?.key ?? '',
    accountName: '',
    externalUserKey,
    pbxId: pbxInfo?.pbx?.id ?? '',
    pbxUserId: pbxInfo?.pbx?.userId ?? '',
  }
}

export const setContextLocalStoragePbxInfo = (pbxInfoParams: PbxInfoParams) => {
  const shellContextLocalStorageKey = formatShellContextLocalStorageKey(pbxInfoParams.externalUserKey)
  const shellContextLocalStorage = getContextFromShellLocalStorage(pbxInfoParams.externalUserKey)

  setToLocalStorage(
    shellContextLocalStorageKey,
    JSON.stringify({ ...shellContextLocalStorage, pbx: { id: pbxInfoParams.pbxId, userId: pbxInfoParams.pbxUserId } }),
  )
}

export const getContextLocalStoragePbxInfo = (externalUserKey: string): { pbx: PbxContext } | null => {
  const shellContextLocalStorageKey = formatShellContextLocalStorageKey(externalUserKey)

  const value = getFromLocalStorage(shellContextLocalStorageKey)
  if (value) {
    return JSON.parse(value)
  }

  return null
}

export const setContextLocalStorageCurrentMountedApps = (externalUserKey: string, currentApps: readonly string[]) => {
  const shellContextLocalStorageKey = formatShellContextLocalStorageKey(externalUserKey)
  const shellContextLocalStorage = getContextFromShellLocalStorage(externalUserKey)

  setToLocalStorage(shellContextLocalStorageKey, JSON.stringify({ ...shellContextLocalStorage, currentApps }))
}

export const getContextLocalStorageCurrentMountedApps = (externalUserKey: string): readonly string[] => {
  const shellContextLocalStorage = getContextFromShellLocalStorage(externalUserKey)
  return shellContextLocalStorage?.currentApps ?? []
}
