/* istanbul ignore file */

import { wait } from '../../common/wait-helpers'
import { responseCanBeCached } from './cache-hydration'

export const getServiceWorker = (): ServiceWorkerContainer | undefined => {
  const navigator = globalThis.navigator

  return 'serviceWorker' in navigator ? navigator.serviceWorker : undefined
}

const startDelayMs = 10000
const throttleDelayMs = 2000

interface EntryMetadata {
  cacheName: string
}
export interface QueueEntry {
  request: Request
  metadata?: EntryMetadata
}

export interface QueueItem extends QueueEntry {
  priority: number
}
type QueueStore = Map<string, QueueItem>

export const rehydrateRequests = async (queueStore: QueueStore): Promise<void> => {
  await wait(startDelayMs)
  while (queueStore.size > 0) {
    const entry = getNextItemByPriority(queueStore)
    if (entry) {
      queueStore.delete(entry.request.url)

      try {
        const cacheName = entry?.metadata?.cacheName
        if (cacheName && entry?.request) {
          await rehydrateRequest(entry.request.clone(), cacheName)
          await wait(throttleDelayMs)
        }
      } catch (error) {
        console.error(`[Service Worker] Rehydration failed`, error)
      }
    }
  }
}

const getNextItemByPriority = (queueStore: QueueStore): QueueItem | undefined =>
  Array.from(queueStore.values()).sort((a, b) => a.priority - b.priority)[0]

const rehydrateRequest = async (request: Request, cacheName: string): Promise<void> => {
  const cache = await caches.open(cacheName)
  const response = await fetchWithValidation(request, cache)
  if (response && responseCanBeCached(response)) {
    console.debug(`[Service Worker] Rehydrated ${request.url}`)
    await cache.put(request, response)
  } else {
    console.debug(`[Service Worker] Response for ${request.url} was not valid and could not be cached`)
  }
}

const fetchWithValidation = async (request: Request, cache: Cache): Promise<Response | undefined> => {
  const fetchOptions: RequestInit = {}
  const cachedResponse = await cache.match(request)
  const etag = cachedResponse?.headers?.get('ETag') || null

  fetchOptions.headers = new Headers(request.headers)
  if (etag) {
    // bind the header with the ETag property to check with the server if the response has changed.
    fetchOptions.headers.set('If-None-Match', etag)
  }

  try {
    const response = await fetch(request, fetchOptions)

    if (etag && response.status === 304) {
      console.debug(`[Service Worker] Cached entry for ${request.url} is still valid (ETag matched)`)
      return
    }

    return response
  } catch (error) {
    console.debug(`[Service Worker] Fetch failed for ${request.url}`, error)
    return
  }
}
