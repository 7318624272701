import { dispatchMessageToServiceWorker } from './dispatch-message'

const SET_EXTENSION_URLS_CACHE_IN_SERVICE_WORKER = 'SET_EXTENSION_URLS_CACHE_IN_SERVICE_WORKER'

interface SetExtensionUrlsCacheMessageData {
  isEnable: boolean
  urls: string[]
}
export const dispatchEnableDisableExtensionUrlsCacheMessage = (data: SetExtensionUrlsCacheMessageData) => {
  dispatchMessageToServiceWorker({ type: SET_EXTENSION_URLS_CACHE_IN_SERVICE_WORKER, ...data })
}
