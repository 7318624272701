import { dispatchMessageToServiceWorker, type ServiceWorkerMessage } from './dispatch-message'

enum ResponseType {
  Basic = 'basic',
  Cors = 'cors',
  Default = 'default',
  Error = 'error',
  Opaque = 'opaque',
}

export const CACHES_TO_REHYDRATE = {
  LANGUAGE: 'language',
  SCRIPTS: 'scripts',
  IMAGES: 'images',
}

export const REHYDRATE_CACHES_FOR_SW_MESSAGE: ServiceWorkerMessage = { type: 'REHYDRATE_CACHES_FOR_SW' }

export const rehydrateServiceWorkerCaches = () => {
  dispatchMessageToServiceWorker(REHYDRATE_CACHES_FOR_SW_MESSAGE)
}

export const isOpaqueResponse = (response: Response) => response.type === ResponseType.Opaque && response.status === 0

export const responseCanBeCached = (response: Response) => response.ok || isOpaqueResponse(response)
