import { cloneDeep } from '../../core/helpers/clone'
import { objectsAreEqual } from '../../core/helpers/compare'
import { getEventBus, eventEmitter } from '../namespaces/event-bus'
import type { PresenceUpdated, UserAppearanceAndDNDUpdated, UserPresenceUpdated } from './models'

export const PresenceServiceNamespace = 'PresenceService'

export const PresenceEvents = {
  presenceUpdate: eventEmitter<PresenceUpdated>(),
  userPresenceUpdate: eventEmitter<UserPresenceUpdated>(),
  userAppearanceAndDNDUpdate: eventEmitter<UserAppearanceAndDNDUpdated>(),
} as const

const { emit } = getEventBus().register(PresenceServiceNamespace, PresenceEvents)

export const presenceUpdate = (payload: PresenceUpdated) => {
  emit.presenceUpdate(payload)
}

let currentUserAppearanceAndDND: UserAppearanceAndDNDUpdated = {}

export const userPresenceUpdate = (payload: UserPresenceUpdated) => {
  emit.userPresenceUpdate(payload)
  const userAppearanceAndDNDUpdated = getUserAppearanceAndDNDFromPayload(payload)
  // check if the userPresenceUpdate is due to a change in userAppearance or DND, which would require a presence changed snackbar to be shown
  // any other presence updates should not trigger a snackbar
  if (shouldShowSchedulePresenceChangedSnackbar(userAppearanceAndDNDUpdated, currentUserAppearanceAndDND)) {
    emit.userAppearanceAndDNDUpdate(userAppearanceAndDNDUpdated)
  }
  currentUserAppearanceAndDND = cloneDeep(userAppearanceAndDNDUpdated)
}

// Snackbars should not be shown if the userAppearance and DND values have not changed
const shouldShowSchedulePresenceChangedSnackbar = (
  userAppearanceAndDNDUpdated: UserAppearanceAndDNDUpdated,
  currentUserAppearanceAndDND: UserAppearanceAndDNDUpdated | null,
) => !objectsAreEqual(userAppearanceAndDNDUpdated, currentUserAppearanceAndDND)

const getUserAppearanceAndDNDFromPayload = (payload: UserPresenceUpdated) => ({
  userAppearance: payload.userAppearance,
  userDoNotDisturb: payload.userDoNotDisturb,
  doNotDisturbDetails: payload.doNotDisturbDetails
    ? {
        dndSources: payload.doNotDisturbDetails?.dndSources,
      }
    : undefined,
})
