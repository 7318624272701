import { Container, Plugins } from '@getgo/container-client'
import { getFromLocalStorage, setToLocalStorage } from '../../common/dom-helpers'
import { getBrowser } from '../../common/user-agent-helpers'
import { LocalStorageKeys } from '../../environments'
import { getShellLogger } from '../../common/logger'
import { type DeviceInfo } from './index'
import { randomID } from '../../common/helpers'

export const internalDomainList = [
  '@logmein.com', // former logmein
  '@goto.com', // current goto
  '@jive.com', // former jive
  '@jedix.com', // domain used for internal mail
  '@guthrie.com', // domain used for training
]

export enum Product {
  WEB = 'gtc-web',
}
export const isInternal = (email?: string) =>
  email ? !!internalDomainList.find(domain => email.toLowerCase().endsWith(domain)) : false

export const getLDAnonymousUUIDKey = () => {
  let uuid: string | null
  uuid = getFromLocalStorage(LocalStorageKeys.launch_darkly_uuid)
  if (!uuid) {
    uuid = randomID()
    setToLocalStorage(LocalStorageKeys.launch_darkly_uuid, uuid)
  }
  return uuid
}

export const getDeviceInfo = async (): Promise<DeviceInfo | undefined> => {
  let { name: deviceBrand, version: deviceModel } = getBrowser()
  try {
    if (Container.isPluginAvailable('ContainerInfo')) {
      const { appVersion, name } = await Plugins.ContainerInfo.getInfo()
      deviceBrand = name
      deviceModel = appVersion
    }
    return { deviceBrand, deviceModel }
  } catch (e) {
    getShellLogger().error('Container Plugins caused an error', e)
  }
}
