/**
 * Compares two objects to determine if they are equal
 * @param modified the modified object
 * @param original the original object
 * @returns a boolean value indicating whether the two objects are equal
 */
export const objectsAreEqual = <T = unknown>(modified: T, original: T): boolean => {
  if (typeof modified === 'object' && typeof original === 'object') {
    const modifiedObject = modified as { [s: string]: unknown }
    const originalObject = original as { [s: string]: unknown }
    const modifiedKeys = Object.keys(modifiedObject)
    const originalKeys = Object.keys(originalObject)
    return (
      modifiedKeys.length === originalKeys.length &&
      modifiedKeys.every(key => objectsAreEqual(modifiedObject[key], originalObject[key]))
    )
  }
  return modified === original
}
