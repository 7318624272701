import { registerShellSettingsHotkeys } from './../packages/navigation/hotkeys'
import type { RegisterApplicationConfig, SingleSpaCustomEventDetail } from 'single-spa'
import { registerApplication, start as startSingleSpa } from 'single-spa'

import { deployedURLs, Environment, isProdEnvironment } from '../environments'
import {
  scaffoldHTML,
  createGotoSettingsExperience,
  createGeneralSettingsExperiences,
  resolveChameleonThemeProviderSkin,
} from './bootstrap-helpers'
import type { ShellAuthContext, ShellConfig, ShellHostConfig, ShellHostMeta } from './models'
import { ShellAuthContextImpl, defaultConfig } from './models'
import { createShellApi } from './shell-api-factory'
import { registerCustomComponents } from './custom-component/components-registration'
import { measureShellLoadingTime, SHELL_DONE_MARK, SHELL_START_MARK, logPerformance } from './performance'
import { initializeCalendar } from './hooks/calendar'
import { initializePendo } from './hooks/pendo'
import { initializeShellController } from './hooks/initialize-shell-controller'
import { initializeMixpanel } from './hooks/mixpanel'
import { initializeContainer } from './hooks/container'
import { initializeI18n } from './hooks/i18n'
import { initializeCommands } from './hooks/launcher-command'
import { initializeDefaultRoute } from './hooks/default-route'
import { initializeContainerQuickAction } from './hooks/container-quick-actions'
import { initializeTopBarVisibility } from './hooks/topbar-visibility'

import { internalExperienceConfigs } from '../experiences/index'
import type { ExtensionConfig, ShellExtension } from '@goto/shell-common'
import { getExtensionsManager } from '../extensions/extensions-manager'
import {
  registerExtensionToSingleSpa,
  createExtensionsSettingsInfo,
  getExtensionIdForUrlFromCache,
  updateCacheAccountInfo,
} from '../extensions/utils'

import { initializeFavicon } from '../common/favicon'
import type { CustomProps, SettingsInfo } from '../common/global-props'
import { keepShellStyleSheetsLastInNodeList } from '../common/mutation-observer'
import { setTitleElementFromBranding } from '../common/page-title'
import {
  INTEGRATION_REDIRECT_PROXY_ROUTE,
  LANDING_ROUTE,
  SHELL_OPTIMIZED_PAGES_ROUTES,
  START_MS_TEAMS_PLUGIN_ROUTE,
  UNAUTHENTICATED_ERROR,
} from '../common/routes'
import { getMountedExperiencesFromStatusEvent, startSingleSpaAppChangeLogger } from '../common/single-spa-debug'
import {
  SINGLE_SPA_BEFORE_MOUNT_ROUTING_EVENT,
  assertIsSingleSpaCustomEvent,
  SINGLE_SPA_BEFORE_ROUTING_EVENT,
} from '../common/single-spa-events'
import {
  getActiveUrlPath,
  getLocationHREF,
  getLocationPathname,
  getWindow,
  navigateToExternal,
  reloadPage,
  setLocationHref,
} from '../common/dom-helpers'
import { GOTO_SHELL_VERSION } from '../common/version'
import { getShellLogger } from '../common/logger'

import { setupAppUpdateTimer } from '../services/app-update/app-update-timer'
import { isInAuthFlowIframe, postMessageFromInsideIframe } from '../services/auth/serverIsLoggedIn/isLoggedIn-iframe'
import { initContainerEvents } from '../services/container/index'
import { isMainWindow } from '../services/container/windows-management'
import {
  setupUnleashFlags,
  setupLaunchDarklyFlags,
  type GoToLicense,
  getFeatureFlagValue,
} from '../services/feature-flags/index'

import { analytics } from '../services/analytics'
import { getHostAppVersion, setHostAppVersion } from '../common/host-app-version'
import { setUnguardedRoutes } from '../services/auth/authentication'
import {
  getAuthContextCurrentAccountKey,
  getAuthContextFromApi,
  getAuthContextFromShellApi,
  getAuthContextIsUserInternal,
} from './helpers/auth-context'
import { findAppByName } from './helpers/registered-app'
import { getShellApiInstance } from '../common/shell-api-helpers'
import { getAccountFromContext } from '../common/shell-api-helpers/context'
import { initializeTrialBanner } from './hooks/trial-banner'
import { initializeMacOSSupportBanner, initializeWindowsSupportBanner } from './hooks/initialize-unsupported-OS-banner'
import { initRoutingEventListener } from './hooks/routing-event-listener'
import { initializeNotificationController } from './hooks/notification-controller'
import { onWindowUnload } from '../common/helpers/window'
import { FeatureFlagsVariations } from '../services/feature-flags/models'
import { initializeKeyboardShortcuts, subscribeSettingsLinkWithKeyboardShortcuts } from './hooks/keyboard-shortcuts'
import { setChameleonWebVersion } from '../common/chameleon-version'
import { getViewsApplication } from '../experiences/configs/views'
import { initializeGlobalSearch } from './hooks/global-search'
import { updateIntegrationMetaData } from './integrations-helpers'
import { initializeBlockedExtensionsBanner } from './hooks/blocked-extensions-banner'
import { initializeQualtrics } from './hooks/qualtrics'
import { getProduct } from '../common/helpers/product'
import { initializeExternalInterface } from '../services/external-interface/external-interface-initialize'
import { subscribeToAccountSwitch } from '../common/account-switch-helpers'
import { getExternalInterface } from '../services/external-interface'
import { navigateToUrlWithRateLimit } from '../common'
import { subscribeToLocaleChangeBegin } from '../common/locale-changed-helpers'
import { rehydrateServiceWorkerCaches } from '../service-workers/utils/cache-hydration'
import { getDeviceInfo } from '../services/feature-flags/utils'
import { getOS } from '../common/user-agent-helpers'
import { type ShellInterface } from './shell-interface'
import { getDefaultChameleonTheme } from '../services/theme'
import {
  appendInHeaderChameleonStylesLinkElement,
  DEFAULT_CHAMELEON_THEME_STYLE_VERSION,
} from './append-chameleon-styles-link'
import { getNavigationItemsService } from '../packages/navigation/navigation-item/navigation-items-service'
import { emitApplicationReadyEvent } from '../services/shell-namespace'
import { initializeSidebarVisibility } from './hooks/sidebar-visibility'
import { emitLinksUpdated } from '../services/navigation/event-bus'
import { redirectToStoredRouteOnRootRoute } from '../packages/navigation/utils'
import { handleIntegrationRedirectToAuthentication } from '../services/router/integration-authentication-router'
import { unregisterServiceWorker } from '../service-workers/shell-sw-registration'
import { dispatchEnableDisableExtensionUrlsCacheMessage } from '../service-workers/utils/extension-cache'
import { initializeScheduleChangedSnackbar } from './hooks/schedule-presence-changed-notification'

const ANALYTICS_APP_LOADING_EVENT = 'GoTo > Load'
const isOnOptimizedRoute = () => !!SHELL_OPTIMIZED_PAGES_ROUTES.find(pageRoute => pageRoute === getLocationPathname())

const defaultUnguardedRoutes = [
  LANDING_ROUTE,
  UNAUTHENTICATED_ERROR,
  START_MS_TEAMS_PLUGIN_ROUTE,
  INTEGRATION_REDIRECT_PROXY_ROUTE,
]

export default class Shell implements ShellInterface {
  private readonly registeredExtensionIds = new Map<string, ShellExtension>()

  private readonly internalApplications = new Map<string, RegisterApplicationConfig<CustomProps>>([
    [
      internalExperienceConfigs.gotoBannerNotificationsConfig.name,
      internalExperienceConfigs.gotoBannerNotificationsConfig,
    ],
    [
      internalExperienceConfigs.gotoSnackbarNotificationsConfig.name,
      internalExperienceConfigs.gotoSnackbarNotificationsConfig,
    ],
    [
      internalExperienceConfigs.gotoModalNotificationsConfig.name,
      internalExperienceConfigs.gotoModalNotificationsConfig,
    ],
  ])

  private readonly unguardedRoutes = new Set<string>()
  private _config: ShellConfig = defaultConfig
  private envConfig: ShellHostConfig | null = null
  private authContext: ShellAuthContext = new ShellAuthContextImpl()
  private readonly themeOnLoad = getDefaultChameleonTheme()

  get config() {
    return this._config
  }
  /* istanbul ignore next */
  set config(config) {
    this._config = {
      ...this._config,
      ...config,
    }
  }

  constructor() {
    updateIntegrationMetaData()
    handleIntegrationRedirectToAuthentication()
    registerCustomComponents()
    scaffoldHTML(this.themeOnLoad)
  }

  getAuthContext() {
    return this.authContext
  }

  addExtensions(extensionConfigs: readonly ExtensionConfig[]): this {
    getExtensionsManager().addExtensions(extensionConfigs)
    return this
  }

  getNavigationOrder() {
    return getNavigationItemsService().getNavigationOrder()
  }

  setNavigationOrder(orderedIds: string[]): this {
    getNavigationItemsService().setNavigationOrder(orderedIds)
    return this
  }

  addChameleonStyles(version: string = DEFAULT_CHAMELEON_THEME_STYLE_VERSION) {
    appendInHeaderChameleonStylesLinkElement(version)
    return this
  }

  /**
   * We set this environment config so that all environment variables are
   * available before any services are spun up.
   */
  private defineEnvironment(config: ShellHostConfig): void {
    this.envConfig = config

    if (this.envConfig) {
      globalThis.shellEnvironmentConfig = this.envConfig
    }
  }

  /**
   * Get the list of unguarded routes for the application
   */
  private defineUnguardedRoutes() {
    defaultUnguardedRoutes.forEach(route => this.unguardedRoutes.add(route))
    const unguardedRoutes = getExtensionsManager().getUnguardedRoutes()
    unguardedRoutes.forEach(unguardedRoute => this.unguardedRoutes.add(unguardedRoute))
  }

  private async initializeFirstExtension() {
    const currentExtensionId = getExtensionIdForUrlFromCache(getLocationHREF())

    if (currentExtensionId) {
      await getExtensionsManager().loadAndInitializeExtensions([currentExtensionId])
    }
  }

  private async initializeApplications() {
    await getExtensionsManager().loadAndInitializeExtensions()
  }

  private async doBeforeAuthentication() {
    getShellLogger().log(`shell version ${GOTO_SHELL_VERSION}`)
    getShellLogger().setContext('shell_version', GOTO_SHELL_VERSION)
    // Add the unguarded routes before authentification
    this.defineUnguardedRoutes()

    if (isMainWindow()) {
      const minuteInMS = 1000 * 60
      let minutes = 0
      getShellLogger().setContext('shell-running-time', minutes)
      setInterval(() => {
        getShellLogger().setContext('shell-running-time', ++minutes)
      }, minuteInMS)
    } else {
      // should use everything from the main window
      return
    }

    if (isInAuthFlowIframe()) {
      postMessageFromInsideIframe()
      throw new Error('Iframe exception')
    }
  }

  private async doAuthentication() {
    await this.doBeforeAuthentication()
    await this.setUnguardedRoutes()
    if (!isMainWindow()) {
      // should use everything from the main window
      this.authContext = getAuthContextFromShellApi(globalThis.opener.shell)
      return
    }

    this.authContext = await getAuthContextFromApi()

    await this.doPostAuthentication()
  }

  private async doPostAuthentication() {
    getShellLogger().setContext('shell-account-key', getAuthContextCurrentAccountKey(this.authContext))
    getShellLogger().setContext(
      'shell_userType',
      getAuthContextIsUserInternal(this.authContext) ? 'internal' : 'external',
    )
    getShellLogger().setContext('shell-product', getProduct())

    await this.doFetchFeatureFlags()
  }

  private async doFetchFeatureFlags() {
    const deviceInfo = await getDeviceInfo()

    const os = getOS()

    const featureFlagParams = {
      numberOfAccounts: this.authContext.accountKeys?.length ?? 0,
      userName: this.authContext.userProfile?.name,
      externalUserKey: this.authContext.userProfile?.id,
      accountKey: this.authContext.contextApi?.account?.key,
      pbxId: this.authContext.pbxId,
      entitlements: this.authContext.user.entitlements,
      roles: this.authContext.user.roles,
      licenses: this.authContext.licenses,
      allAccountKeys: this.authContext.accountKeys ?? [],
      deviceBrand: deviceInfo?.deviceBrand,
      deviceModel: deviceInfo?.deviceModel,
      osName: os.name,
      osVersion: os.version,
      hostname: location.hostname,
      partnerAccountKey: this.authContext.currentAccount?.partnerAccountKey,
      userCreationDate: this.authContext.userCreationDate,
      currentAccountName: this.authContext.currentAccount?.name,
    }

    const email = this.authContext.userProfile?.firstEmail
    const product = getProduct()

    const licenseKeys =
      this.authContext.licenses?.reduce<string[]>((acc, license: GoToLicense) => {
        if (license.enabled) {
          acc.push(license.key)
        }
        return acc
      }, []) ?? []

    const unleashParams = { email, licenseKeys, product, ...featureFlagParams }

    const shellDisableServiceWorker = getFeatureFlagValue<boolean>(
      FeatureFlagsVariations.SHELL_DISABLE_SERVICE_WORKER,
      false,
    )

    await Promise.allSettled([setupLaunchDarklyFlags(featureFlagParams), setupUnleashFlags(unleashParams)])

    const shellDisableServiceWorkerNewValue = getFeatureFlagValue<boolean>(
      FeatureFlagsVariations.SHELL_DISABLE_SERVICE_WORKER,
      false,
    )
    const shellEnabledExtensionUrlsCacheInServiceWorker = !!getFeatureFlagValue<boolean>(
      FeatureFlagsVariations.SHELL_ENABLE_EXTENSION_URLS_CACHE_IN_SERVICE_WORKER,
    )

    getShellLogger().setContext('shell_sw_enabled', !shellDisableServiceWorkerNewValue)
    getShellLogger().setContext('shell_sw_cache_only_extension_urls', shellEnabledExtensionUrlsCacheInServiceWorker)

    if (shellDisableServiceWorkerNewValue && !shellDisableServiceWorker) {
      await unregisterServiceWorker()
      reloadPage()
    }
  }

  private async doInitializeAPI() {
    if (isMainWindow()) {
      globalThis.shell = Object.freeze(createShellApi(this.authContext))
    } else {
      globalThis.shell = globalThis.opener.shell
    }
    await this.doPostInitializeAPI()
  }

  private shouldRedirectForExtendedRelease(): boolean {
    if (isProdEnvironment()) {
      const accountKey = getAccountFromContext()?.key ?? ''
      const selectedAccount = getShellApiInstance().user.getAccountByKey(accountKey)

      return (selectedAccount?.slowChannelEnabled && !this.isOnExtended()) ?? false
    }
    return false
  }

  private isOnExtended(): boolean {
    return getLocationHREF().includes(deployedURLs.extended_prod)
  }

  private async doPostInitializeAPI() {
    if (this.shouldRedirectForExtendedRelease()) {
      setLocationHref(`https://${deployedURLs.extended_prod}${getActiveUrlPath() ?? ''}`)
    }

    if (isMainWindow()) {
      initContainerEvents()
    }

    await initializeShellController(this)
    await initializeNotificationController(this)
    await initializeContainer(this)
    await initializeCommands(this)
    if (isMainWindow()) {
      initRoutingEventListener()
    }
    await initializeMixpanel(this)

    analytics.track(ANALYTICS_APP_LOADING_EVENT, { Event: 'Began loading' })

    if (isMainWindow()) {
      await initializeI18n(this.authContext?.me?.locale)
    }
    await initializeSidebarVisibility(this)
    await initializeTopBarVisibility(this)
    await initializeKeyboardShortcuts(this)
    if (getFeatureFlagValue(FeatureFlagsVariations.SHELL_SCHEDULE_SETTINGS, false) && isMainWindow()) {
      await initializeScheduleChangedSnackbar(this)
    }

    await subscribeSettingsLinkWithKeyboardShortcuts(this)

    try {
      /**
       * These functions can throw, which goto-app interprets as a fatal exception that prevented shell from starting
       * (see https://jira.ops.expertcity.com/browse/SCORE-1753), and does a redirect to the generic error page.
       * However an exception here is not fatal since it's thrown after single spa start.
       *
       * This is a band aid until we can have something that allows us to separately handle errors
       * thrown by non-essential functions.
       */

      keepShellStyleSheetsLastInNodeList()
      initializeFavicon()
      setTitleElementFromBranding()
      if (isMainWindow()) {
        setupAppUpdateTimer()
      }
    } catch (e) {
      getShellLogger().error('shell threw a non-fatal exception when starting ', e)
    }
  }

  /**
   * Hooks that should be executed after single-spa start
   */
  private async doPostStart() {
    try {
      if (isMainWindow()) {
        await initializeCalendar(this)
      }

      // Rehydrate the service worker caches only if the SW is not disabled.
      if (!getFeatureFlagValue(FeatureFlagsVariations.SHELL_DISABLE_SERVICE_WORKER)) {
        rehydrateServiceWorkerCaches()
      }

      // Do not wait, since it involves loading JS code not required for app load
      initializePendo(this)
      initializeQualtrics()
      initializeTrialBanner()
      initializeWindowsSupportBanner()
      initializeMacOSSupportBanner()
      initializeBlockedExtensionsBanner()
    } catch (e) {
      // Can be used for debugging purposes but needed to be logged by Shell logger
      console.warn('shell threw a non-fatal exception when starting ', e)
    }
  }

  private readonly handleBeforeRoutingEvent = (event: CustomEvent<SingleSpaCustomEventDetail>) => {
    if (event.detail.newUrl !== event.detail.oldUrl) {
      if (event.detail.cancelNavigation) {
        event.detail.cancelNavigation()
      }
      if (!isOnOptimizedRoute()) {
        navigateToExternal(event.detail.newUrl)
      }
    }
  }

  private interceptBeforeRoutingEvent(): void {
    getWindow().addEventListener(SINGLE_SPA_BEFORE_ROUTING_EVENT, this.handleBeforeRoutingEvent)
    onWindowUnload(() => {
      getWindow().removeEventListener(SINGLE_SPA_BEFORE_ROUTING_EVENT, this.handleBeforeRoutingEvent)
    })
  }

  private async doInitializeShellApplications() {
    if (getFeatureFlagValue(FeatureFlagsVariations.SHELL_PAGES_OPTIMIZATION) && isOnOptimizedRoute()) {
      this.interceptBeforeRoutingEvent()
    } else {
      await this.initializeApplications()
      await this.doPostInitializeShellApplications()
      this.registerExtensionsAndSettingsToSingleSpa()
      updateCacheAccountInfo()
    }
  }

  private async doInitializeFirstShellApplication() {
    if (getFeatureFlagValue(FeatureFlagsVariations.SHELL_LOAD_SINGLE_EXTENSION)) {
      await this.initializeFirstExtension()
      this.registerLoadedExtensionsToSingleSpa()
    }
  }

  private async doPostInitializeShellApplications() {
    if (isMainWindow()) {
      initializeContainerQuickAction(this)
    }
  }

  private async setUnguardedRoutes() {
    await setUnguardedRoutes(Array.from(this.unguardedRoutes))
  }

  private registerViewsApplications() {
    const viewsApplications = getViewsApplication()
    viewsApplications.forEach(application => {
      registerApplication(application)
    })
  }

  async start(
    config: ShellHostConfig = {
      environment: Environment.ED,
    },
  ) {
    try {
      performance.mark(SHELL_START_MARK)

      dispatchEnableDisableExtensionUrlsCacheMessage({
        isEnable: !!getFeatureFlagValue(FeatureFlagsVariations.SHELL_ENABLE_EXTENSION_URLS_CACHE_IN_SERVICE_WORKER),
        urls: [],
      })

      this.addSingleSPAListeners()
      this.defineEnvironment(config)

      // `getShellLogger()` can be used only after the environment is defined.
      getShellLogger().log(`Host Application version: ${getHostAppVersion()}`)
      getShellLogger().setContext('host_version', getHostAppVersion())

      subscribeToAccountSwitch()
      subscribeToLocaleChangeBegin()
      await initializeExternalInterface()
      resolveChameleonThemeProviderSkin()
      await this.doAuthentication()

      await this.doInitializeAPI()

      dispatchEnableDisableExtensionUrlsCacheMessage({
        isEnable: !!getFeatureFlagValue(FeatureFlagsVariations.SHELL_ENABLE_EXTENSION_URLS_CACHE_IN_SERVICE_WORKER),
        urls: getExtensionsManager().getExtensionUrls(),
      })

      this.registerViewsApplications()
      this.registerInternalApplications()

      if (isMainWindow()) {
        await initializeGlobalSearch(this)
      }

      redirectToStoredRouteOnRootRoute()

      await this.doInitializeFirstShellApplication()

      startSingleSpa({
        urlRerouteOnly: true,
      })
      getShellLogger().log('single-spa start')

      await this.doInitializeShellApplications()

      await initializeDefaultRoute(this)

      //This event indicates that all extensions have settled their initialization process.
      emitApplicationReadyEvent()
      //This event indicates that the navigation links have been updated.
      emitLinksUpdated()

      if (isMainWindow()) {
        registerShellSettingsHotkeys()
      }

      analytics.track(ANALYTICS_APP_LOADING_EVENT, { Event: 'Initialize single SPA' })

      performance.mark(SHELL_DONE_MARK)
      const shellLoadingTimeMeasure = measureShellLoadingTime()

      analytics.track(ANALYTICS_APP_LOADING_EVENT, {
        Event: 'Finished loading',
        LoadingTime: shellLoadingTimeMeasure?.duration ?? 0,
        CurrentTheme: this.themeOnLoad,
      })
      logPerformance()
    } catch (e: any) {
      await initializeI18n()
      if (e.message === 'Iframe exception') {
        return
      }
      getShellLogger().error('Unexpected error', e)

      if (getExternalInterface().isCompanion) {
        return navigateToUrlWithRateLimit(LANDING_ROUTE)
      } else {
        throw e
      }
    }
    await this.doPostStart()
  }

  setMeta(meta: ShellHostMeta): void {
    setHostAppVersion(meta.hostAppVersion)
    setChameleonWebVersion(meta.chameleonWebVersion)
  }

  private registerLoadedExtensionsToSingleSpa() {
    const extensions = getExtensionsManager().getExtensions()
    extensions.forEach(extension => {
      registerExtensionToSingleSpa(extension)
      this.registeredExtensionIds.set(extension.id, extension)
    })
    startSingleSpaAppChangeLogger()
  }

  private registerExtensionsAndSettingsToSingleSpa() {
    const settingsInfos: SettingsInfo[] = []

    const extensions = getExtensionsManager().getExtensions()

    const extensionsToRegister = extensions.filter(extension => !this.registeredExtensionIds.has(extension.id))
    extensionsToRegister.forEach(extension => {
      registerExtensionToSingleSpa(extension)
      this.registeredExtensionIds.set(extension.id, extension)
    })
    const extensionsSettingsInfo: readonly SettingsInfo[] = createExtensionsSettingsInfo(extensions)
    settingsInfos.push(...extensionsSettingsInfo)

    // Shell main settings application (container)
    const gotoSettingsApplication = createGotoSettingsExperience(settingsInfos)
    // General settings pages
    const generalSettingsApplications = createGeneralSettingsExperiences()
    // Specific Shell views (can be considered as internal Shell applications)

    this.internalApplications.set(gotoSettingsApplication.name, gotoSettingsApplication)
    generalSettingsApplications.forEach(generalSettingsApplication =>
      this.internalApplications.set(generalSettingsApplication.name, generalSettingsApplication),
    )

    registerApplication(gotoSettingsApplication)

    generalSettingsApplications.forEach(application => {
      try {
        registerApplication(application)
      } catch (e) {
        getShellLogger().error(`Error while registering application to single-spa`, application, e)
      }
    })
    startSingleSpaAppChangeLogger()
  }

  private registerInternalApplications() {
    // Internal Shell experiences
    const internalApplications: RegisterApplicationConfig<CustomProps>[] = Array.from(
      this.internalApplications.values(),
    )

    internalApplications.forEach(application => {
      registerApplication(application)
    })
  }

  private addSingleSPAListeners() {
    /**
     * Make sure that we are automatically updating the shell config based on the experiences that we are about to mount
     */
    window.addEventListener(SINGLE_SPA_BEFORE_MOUNT_ROUTING_EVENT, event => {
      assertIsSingleSpaCustomEvent(event)
      const mountedExperiences = getMountedExperiencesFromStatusEvent(event)
      this.configure(mountedExperiences)
    })
  }

  /**
   * The configure function is meant to set the config object and then it calls the single spa checkActivityFunctions, this then
   * calls the activeWhen of each app and the UI is updated as expected
   *
   * @param event single SPA before-mount-routing-event event object
   *
   */
  private configure(mountedApps: ReturnType<typeof getMountedExperiencesFromStatusEvent>) {
    this.config = mountedApps
      .filter(appMounted => appMounted !== 'goto/navigation')
      .map(appName =>
        findAppByName(appName, getExtensionsManager().getRegisteredApplications(), this.internalApplications),
      )
      .reduce(
        (config, application) => ({
          ...config,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...((application?.customProps as any)?.shellConfig ?? {}),
        }),
        this.config,
      )
  }
}
