import { asTranslationKey } from '../common/translate-helpers/i18n-utils'
import { Appearance, Status, UserAppearance } from '../services/presence'

export const getAppearanceTranslationKey = (appearance: Appearance, forPresenceSelection: boolean, status?: Status) => {
  switch (appearance) {
    case Appearance.ONLINE:
      return asTranslationKey('Available')
    case Appearance.BUSY:
      return getBusyStatusTranslationKey(status)
    case Appearance.DO_NOT_DISTURB:
      return asTranslationKey('Do not disturb')
    case Appearance.AWAY:
      return asTranslationKey('Away')
    case Appearance.AUTO_UPDATE:
      return asTranslationKey('Auto-update')
    default:
      return forPresenceSelection ? asTranslationKey('Appear offline') : asTranslationKey('Offline')
  }
}

export const getUserAppearanceTranslationKey = (userAppearance?: UserAppearance) => {
  switch (userAppearance) {
    case UserAppearance.ONLINE:
      return asTranslationKey('Available')
    case UserAppearance.BUSY:
      // User set
      return asTranslationKey('Busy')
    case UserAppearance.AWAY:
      return asTranslationKey('Away')
    case UserAppearance.NONE:
      return asTranslationKey('Auto-update')
    default:
      return asTranslationKey('Offline')
  }
}

const getBusyStatusTranslationKey = (status: Status | undefined) => {
  switch (status) {
    case Status.IN_A_CALL:
      return asTranslationKey('On a call')
    case Status.IN_A_MEETING:
      return asTranslationKey('In a meeting')
    case Status.IN_A_CALENDAR_EVENT:
      return asTranslationKey('Calendar event')
    default:
      return asTranslationKey('Busy')
  }
}
