import { getServiceWorker } from './sw-helpers'

export interface ServiceWorkerMessage extends Record<string, unknown> {
  type: string
}

export const dispatchMessageToServiceWorker = async (message: ServiceWorkerMessage): Promise<void> => {
  const serviceWorker = getServiceWorker()

  if (serviceWorker?.controller) {
    try {
      serviceWorker.controller.postMessage(message)
    } catch (error) {
      console.warn(error)
    }
  }
}
